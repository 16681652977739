<!-- 模板 -->
<template>
	<div class="box">
		<div class="contant wu">
			<img :src="$baseUrl + logo_down" @click="btn" alt="" class="com_img" />
			<div class="footer">
				<div class="foot_l" style="">
					<div class="links">
						<div v-for="(it, i) in arr_list" :key="i" class="tits">
							<div class="name">{{ it.name }}</div>
							<div class="name_ab" v-for="(its, index) in it.list" :key="index" @click="jump(its)">
								{{ its.title }}
							</div>
						</div>
					</div>
				</div>
				<div class="foot_l">
					<div class="links">
						<div v-for="(it, i) in arr" :key="i" class="tits">
							<div class="name">{{ it.name }}</div>
							<div class="name_ab" v-for="(its, index) in it.list" :key="index" @click="jumps(its.url)">
								{{ its.title }}
							</div>
						</div>
						<div v-for="(it, i) in arr1" :key="i" class="tits">
							<div class="name">{{ it.name }}</div>
							<el-popover v-for="(its, index) in it.list" :key="index" class="name_ab" style="text-align: center;"
								placement="top-start" :title="its.title" width="200" trigger="hover"
								>
								<div>{{its.tit}}</div>
								<img v-if="its.img!=''" style="width: 200px;text-align: center;" :src="its.img" alt="">
								<el-button type="text" style="margin-top: 25px;" slot="reference">{{ its.title }}
								</el-button>
							</el-popover>

							<!-- <div
			    class="name_ab"
			    v-for="(its, index) in it.list"
			    :key="index"
			    @click="jumps(its.url)"
			  >
			    {{ its.title }}
			  </div> -->
						</div>
					</div>
				</div>
				<div class="foot_r">
					<div class="phone">
						<div class="boxes">
							<img :src="$baseUrl + data.kefu" alt="" />
							<div>中国足道客服微信</div>
						</div>
						<div class="boxes">
							<img :src="$baseUrl + data.gongzhonghao" alt="" />
							<div>中国足道官方公众号</div>
						</div>
						<div class="boxes">
							<img :src="$baseUrl + data.xiaochengxu" alt="" />
							<div>中国足道小程序</div>
						</div>
						<div class="boxes">
							<img :src="$baseUrl + data.vedio_code" alt="" />
							<div>视频号</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="wu">
			<div class="adress">
				<div>加盟热线：{{ data.join_hotline }}</div>
				<div>固定电话：{{ data.phone }}</div>
				<div>联系地址：{{ data.address }}</div>
			</div>
		</div>

		<div class="time">
			<div class="time1" @click="beian">
				版权所有：{{ data.banquan }} {{ data.beian }}
			</div>
			<!-- <div class="time2" @click="beian">
        <span>备案编号：</span><span>皖ICP备17011866号</span>
      </div> -->
		</div>
	</div>
</template>

<script>
	import request from "../utils/request.js";
	export default {
		name: "",
		data() {
			return {
				logo: "",
				arr_list: [{
					name: "友情链接",
					list: [],
				}, ],
				arr: [{
						name: "业内链接",
						list: [{
								title: "行业民企",
								url: "/index",
							},
							{
								title: "行业大师",
								url: "/index",
							},
							{
								title: "经营管理",
								url: "/index",
							},
							{
								title: "黑人黒企",
								url: "/index",
							},
							{
								title: "行业慈善",
								url: "/index",
							},
						],
					},
					
				],
				arr1: [{
					name: "联系方式",
					list: [{
							title: "官方客服",
							
							img:'',
							tit:'',
						},
						{
							title: "联系电话",
							
							img:'',
							tit:'',
						},
						{
							title: "线下地址",
							
							img:'',
							tit:'',
						},
					],
				}, ],
				data: {},
				logo_down: "",
			};
		},
		props: [],
		components: {},
		computed: {},
		filters: {},
		methods: {
			async youqing() {
				let options = {
					url: "/index/friend",
					type: "post",
				};
				try {
					let res = await request(options);

					this.arr_list[0].list = res.data.data;

					if (res.data.code !== 1) {
						return this.$message.error("请求数据失败");
					}
				} catch (error) {
					this.$message.error("请求数据失败");
				}
			},
			async common() {
				let options = {
					url: "/index/common",
					type: "post",
				};
				try {
					let res = await request(options);
					if (res.data.code !== 1) {
						return this.$message.error("请求数据失败");
					}
					console.log(res.data)
					// console.log(this.arr1[0].list[0].img);
					this.arr1[0].list[0].img=this.$baseUrl+res.data.data.kefu
					this.arr1[0].list[1].tit=res.data.data.phone
					this.arr1[0].list[2].tit=res.data.data.address
					console.log(this.arr1[0].list);
					this.data = res.data.data;
					this.logo = res.data.data.logo;
					this.logo_down = res.data.data.logo_down;
				} catch (error) {
					this.$message.error("请求数据失败");
				}
			},
			jump(e) {
				window.location.href = e.website;
			},
			jumps(url) {
				this.$router.push(url);
			},
			// 备案
			beian() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
			},
			btn() {
				this.$router.push("/index");
			},
			about() {
				this.$router.push("/about");
			},
			service() {
				this.$router.push("/service");
			},
			wcases() {
				this.$router.push("/cases");
			},
		},
		created() {
			this.common();
			this.youqing();
		},
		mounted() {},
	};
</script>

<style lang="less" scoped>
	//@import url(); 引入公共css类
	/deep/.el-button--text {
		color: #fff !important;
		padding: 0 !important;
		
	}

	.box {
		background-color: #252121;

		.contant {
			padding-top: 30px;

			.com_img {
				height: 70px;
			}

			.footer {
				display: flex;
				// justify-content: space-between;
				padding: 20px 0 60px;
				border-bottom: 2px solid rgba(255, 255, 255, 0.39);

				.foot_l {
					cursor: pointer;

					// padding-left: 20px;
					.links {
						display: flex;

						.tits {
							flex: 1;
							margin-right: 60px;

							.name {
								font-size: 16px;
								font-weight: 400;
								line-height: 27px;
								color: #ffffff;
								opacity: 1;
								width: 100px;
							}

							.name_ab {
								font-size: 14px;
								font-weight: 400;
								line-height: 24px;
								color: #ffffff;
								opacity: 0.8;
								margin-top: 20px;

								&:hover {
									color: #f00 !important;
								}
							}
						}
					}
				}

				.foot_r {
					.ours {
						font-size: 16px;
						// font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 27px;
						color: #ffffff;
						opacity: 1;
					}

					.phone {
						display: flex;

						.boxes {
							text-align: center;
							margin-left: 0;
							
							width: 180px;

							img {
								width: 120px;
							}

							div {
								margin-top: 30px;
								font-size: 18px;
								font-weight: 400;
								line-height: 34px;
								color: #ffffff;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	.adress {
		display: flex;
		padding: 20px;
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;

		div {
			&:nth-child(1) {
				margin-right: 60px;
			}

			&:nth-child(2) {
				margin-right: 200px;
			}
		}
	}

	.time {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #000;
		padding: 10px 0;

		// margin-top: 91px;
		.time1 {
			margin-right: 60px;
			font-size: 14px;
			// font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 24px;
			color: #ffffff;
			opacity: 0.8;
			cursor: pointer;
		}

		.time2 {
			span {
				&:first-child {
					font-size: 14px;
					// font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 24px;
					color: #ffffff;
					opacity: 0.8;
				}

				&:last-child {
					font-size: 14px;
					// font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 24px;
					color: #0070ff;
					opacity: 1;
				}
			}
		}
	}
</style>
