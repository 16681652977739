<!-- 模板 -->
<template>
  <div>
    <div
      :class="navBarFixed == true ? 'navBarWrap' : ''"
      style="background-color: #fff"
    >
      <div class="contant" ref="element">
        <img :src="$baseUrl + logo" alt="" class="imgs" />
        <ul>
          <li
            v-for="(it, i) in DataArr"
            @click="jumpTo(i)"
            :key="i"
            :class="active == i ? 'on' : ''"
          >
            <div class="one">
              <div class="line">
                <p class="line1"></p>
                <p class="line2"></p>
              </div>
              <div class="name">
                <div class="n">{{ it.name }}</div>
                <div class="e">{{ it.title }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../utils/request.js";
let _this;
export default {
  name: "",
  data() {
    return {
      navBarFixed: false,
      style: {},
      opacity: 0,
      arr: [],
      DataArr: [
        {
          url: "/index",
          name: "行业名企",
          title: "FAMOUS",
        },
        {
          url: "/service",
          name: "行业大师",
          title: "INDUSTRY MASTER",
        },
        {
          url: "/cases",
          name: "经营管理",
          title: "ADMINISTRATION",
        },
        {
          url: "/about",
          name: "黑企黑人",
          title: "BlACK ENTERPRISES",
        },
        {
          url: "/call",
          name: "行业公益",
          title: "INDUSTRY CHARITY",
        },
      ],
      logo: "",
    };
  },
  props: {
    active: Number,
  },
  components: {},
  computed: {},
  filters: {},
  created() {
    _this = this;
    _this.getGoodsList();
    _this.common();
  },
  methods: {
    async common() {
      let options = {
        url: "/index/common",
        type: "post",
      };
      try {
        let res = await request(options);

        this.logo = res.data.data.logo;
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
      } catch (error) {
        this.$message.error("请求数据失败");
      }
    },
    // 获得商品列表数据
    async getGoodsList() {
      let options = {
        url: "/index/channel",
        type: "post",
      };
      try {
        let res = await request(options);

        for (let i = res.data.data.length; i < res.data.data.length; i++) {
          this.DataArr[i].name = res.data.data[i].name;
        }
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        // this.$message.success("请求数据成功");
      } catch (error) {
        this.$message.error("请求数据失败");
      }
    },

    jumpTo(index) {
      let path = _this.DataArr[index].url;
      _this.$router.push(path);
    },
    watchScroll() {
      //滚动距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop);
      //  当导航栏滚动距离大于头部图片高度实现吸顶效果
      if (scrollTop > 99) {
        //为导航栏加上navBarFixed类，实现透明度随滚动改变，并且吸顶改变导航栏样式
        this.navBarFixed = true;
        this.opacity = Math.abs(Math.round(scrollTop)) / 3300;
        // console.log(this.opacity);
        this.style = {
          background: `rgba(255, 255, 255, 1)`,
          // backdropFilter: ` blur(10px)`,
        };
      } else {
        this.navBarFixed = false;
        this.style = "";
      }
    },
  },

  mounted() {
    window.addEventListener("scroll", this.watchScroll);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.watchScroll);
    next();
  },
};
</script>

<style lang="less" scoped>
//@import url(); 引入公共css类

.contant {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  height: 90px;

  .imgs {
    width: 197px;
    margin: 10px 0;
    margin-right: 30px;
  }
  ul {
    display: flex;
    // align-items: center;

    li {
      // padding: 20px 0px 20px 30px;
      width: 188px;
      cursor: pointer;
      .one {
        height: 50px;
        display: flex;
        margin-top: 20px;
        margin-left: 30px;
        // width: 160px;
        .line {
          display: flex;
          background-color: #fff;
          .line1 {
            width: 3px;
            height: 50px;
            background: #7b7979;
            opacity: 1;
          }
          .line2 {
            margin-left: 1px;
            width: 1px;
            height: 50px;
            background: #7b7979;
            opacity: 1;
          }
        }
        .name {
          margin-left: 10px;
          .n {
            font-size: 18px;
            font-weight: bold;
            line-height: 31px;
            color: #7b7979;
            opacity: 1;
          }
          .e {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #7b7979;
            opacity: 1;
          }
        }
      }
    }
    .on {
      background-image: url("../assets/image/2.png");
      .one {
        height: 50px;
        display: flex;
        .line {
          display: flex;
          height: 50px;
          background: #fff;
          opacity: 1;
          padding-left: 2px;

          .line1 {
            width: 1px;
            height: 50px;
            background: #7b7979;
          }
          // .line2 {
          //   margin-left: 1px;
          //   width: 1px;
          //   height: 50px;
          //   background: #7b7979;
          //   opacity: 1;
          // }
        }
        .name {
          margin-left: 10px;
          .n {
            font-size: 18px;
            font-weight: bold;
            line-height: 31px;
            color: #fff;
            opacity: 1;
          }
          .e {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
